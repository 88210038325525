import "./Tecnologias.css";

import reactIcon from "../assets/react.png";
import angularIcon from "../assets/angular.png";
import jsIcon from "../assets/javascript.png";
import mongodbIcon from "../assets/mongodb.png";
import nodeIcon from "../assets/node.png";
import phpIcon from "../assets/php.png";
import sassIcon from "../assets/sass.png";
import cssIcon from "../assets/css.png";
import htmlIcon from "../assets/html.png";
import aiIcon from "../assets/illustrator.png";
import psIcon from "../assets/photoshop.png";
import xdIcon from "../assets/adobexd.png";

const Tecnologias = (props) => {
  return (
    <div id="tecnologias" className="row">
      <h2 className="text-center">{props.idioma === "en" ? "Skills" : "Tecnologías"}</h2>
      <div className="row teconologias_iconos d-flex align-items-center">
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono react" src={reactIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono angular" src={angularIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono js" src={jsIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono node" src={nodeIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono mongo" src={mongodbIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono php" src={phpIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono html" src={htmlIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono css" src={cssIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono sass" src={sassIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono ai" src={aiIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono ps" src={psIcon}></img>
        </div>
        <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
          <img className="tecnologias_icono xd" src={xdIcon}></img>
        </div>
      </div>
    </div>
  );
};

export default Tecnologias;
