import React from 'react';

import Card from './Templates/Card'
import './Aboutme.css'
import gymImg from '../assets/gym.png'
import bookImg from '../assets/leer.png'

const Aboutme = (props) => {

    var title = "About me"
    var aboutMe1 = "I'm  a self-taught developer, specialized in <b>Front-End Development</b>, and enthusiastic about <b>UX/UI</b>. I'm always learning about new technologies and seeking ways to code with the least amount of code lines."
    var aboutMe2 = "Leaving code aside, in my free time i enjoy going to the gym, devouring some books, listening to podcasts, and spending time with my family and friends."

    if(props.idioma === "es"){
        title = "Sobre mí"
        aboutMe1 = "Soy un Desarrollador autodidacta, mi especialidad es el <b>Desarrollo Front-End</b> y entusiasta del <b>UX/UI</b>. Constantemente aprendiendo sobre nuevas tecnologías y buscando maneras de programar con la menor cantidad de líneas de codigo."
        aboutMe2 = "Dejando del lado al código, en mi tiempo libre disfruto con ir al gimnasio, devorar algún que otro libro, escuchar podcasts y compartir con mi familia y amigos."
    }

    return(
        <div id='sobre-mi' className='row sobre-mi align-items-center'>
            <h2 className='sobre-mi_title text-center'>{title}</h2>
            <p 
                className='sobre-mi_p text-center'
                dangerouslySetInnerHTML={{
                    __html: aboutMe1,
                  }}
            >
                
                
            </p>
            <div className='col-12 col-md-8 sobre-mi_content'>
                <p className="sobre-mi_extra">{aboutMe2}</p>
            </div>
            <div className='col-12 col-md-3 sobre-mi_img'>
                <Card shadowOff={true}>
                <img className='sobre-mi_imgGym' src={gymImg}></img>
                </Card>
            </div>
        </div>
    )
}

export default Aboutme 