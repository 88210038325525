import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./Components/Home";
import Header from "./Components/Header";
import Proyecto from "./Components/Proyecto";


function App() {

  const [idioma, setIdioma] = useState('en');
  console.log(idioma)

  const changeIdioma = (idiomaChanged) => {
    setIdioma(idiomaChanged)
  }

  return (
    <Routes>
      <Route path="/" element={<Header idioma={idioma} changeIdioma={changeIdioma} />}>
        <Route path="/" element={<Home idioma={idioma} />}/>
        <Route path="proyecto/:nombre" element={<Proyecto idioma={idioma} />} />
        <Route path="*" element={<Home />}/>
      </Route>
    </Routes>
  );
}

export default App;
