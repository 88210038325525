import { useRef } from 'react'
import emailjs from '@emailjs/browser'

import './Contacto.css'

import Card from './Templates/Card'
import correo from '../assets/correo.png'


const Contacto = (props) => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm("service_qz21ae5", "template_hmj2fc9", form.current, "5cSmftH6FPSJhQDzE").then(
            (result) => {
                console.log(result)
            },
            (error) => {
                console.log(error.text)
            }
        )
    }

    return(
        <div id="contacto" className='row contacto justify-content-around'>
            <h2 className='text-center'>Contacto</h2>
            <div className="col-12 col-md-5">
                <Card noShadow={true}>
                    <p>{props.idioma === "en" ? "If you have any inquiries, projects in mind, or are interested in hiring me, you can send me an email or submit your message using this form" : "Si tienes en mente alguna consulta, proyecto o estás interesado en contratarme puedes mandarme un correo, o hacer llegar tu mensaje desde este formulario!"}</p>
                    <div className="contacto_correo d-flex align-items-center flex-wrap" >
                        <img src={correo}></img><p className='m-0 ps-2'>ceberus1704@gmail.com</p>
                    </div>
                </Card>
            </div>
            <div className="col-12 col-md-7">
                <Card>
                    <form className="contacto_form" ref={form} onSubmit={sendEmail}>
                        <label>{props.idioma === "en" ? "Name" : "Nombre"}</label>
                        <input className="" type="text" name="user_name" placeholder={props.idioma === "en" ? "Your name" : "Tu nombre"} required></input>
                        <label>{props.idioma === "en" ? "E-mail" : "Correo"}</label>
                        <input type="email" name="user_email" placeholder={props.idioma === "en" ? "Your e-mail" : "Tu correo"} required></input>
                        <label>{props.idioma === "en" ? "Your message" : "Tu mensaje"}</label>
                        <textarea type="email" name="message" placeholder={props.idioma === "en" ? "Your message" : "Tu mensaje"} required></textarea>
                        <input className="btn btn_primary submit" type="submit" value={props.idioma === "en" ? "Send" : "Enviar"}></input>
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default Contacto