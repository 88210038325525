import { Outlet } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Fragment } from "react";
import { Container } from "react-bootstrap";

import Card from "./Templates/Card";


import "./Header.css";
import Tecnologias from "./Tecnologias";

const Header = (props) => {

  var home = "Home"
  var aboutMe = "About me"
  var tecnologies = "Skills"
  var projects = "Projects"
  var contact = "Contact"
  var flag = "🇪🇸"

  if(props.idioma === "es"){
    home = "Inicio"
    aboutMe = "Sobre mí"
    tecnologies = "Tecnologias"
    projects = "Proyectos"
    contact = "Contacto"
    flag = "🇬🇧"
  }


  // Función para alternar el estado de la variable
  const toggleVariable = () => {
    var idioma = props.idioma === "en" ? "es" : "en"
    props.changeIdioma(idioma)
  };

  return (
    <Fragment>
      
      <Container>
        <header id="inicio" className="header d-flex justify-content-center">
          <Card>
            <div className="nav-menu d-flex flex-wrap justify-content-center">
              <HashLink smooth className="nav-menu_link py-2 px-4" to="/">
                {home}
              </HashLink>
              <HashLink spy={true} smooth={true} duration={500} className="nav-menu_link py-2 px-4" to="/#sobre-mi">
                {aboutMe}
              </HashLink>
              <HashLink spy={true} smooth={true} duration={500}  className="nav-menu_link py-2 px-4" to="/#tecnologias">
                {tecnologies}
              </HashLink>
              <HashLink spy={true} smooth={true} duration={500}  className="nav-menu_link py-2 px-4" to="/#proyectos">
                {projects}
              </HashLink>
              <HashLink  className="nav-menu_link py-2 px-4" to="/#contacto">
                {contact}
              </HashLink>
              <HashLink  className="nav-menu_link country py-2 px-4" to="/" onClick={toggleVariable} >
                {flag}
              </HashLink>
            </div>
          </Card>
        </header>
      </Container>
      <Outlet />
    </Fragment>
  );
};

export default Header;
