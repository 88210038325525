import { Link } from "react-router-dom";
import "./Proyectos.css";
import { proyectsEN } from "../Data";
import { proyectsES } from "../Data";

import Card from "./Templates/Card";

import cp from "../assets/cp-mobile.jpg";
import cp1 from "../assets/cp1.png";
import cp2 from "../assets/cp2.png";

import cotizador from "../assets/cotizador.png";

import bodega from "../assets/bodega.png";
import bodega2 from "../assets/bodega2.png";
import bodega3 from "../assets/bodega3.jpg";

import food from "../assets/food.png";

import huella from "../assets/huella.png";
import huella2 from "../assets/huella2.png";
import huella3 from "../assets/huella3.png";

import gastos from "../assets/gastos.png";

const Proyectos = (props) => {
  var proyects = proyectsEN

  if (props.idioma === "es") {
    proyects = proyectsES
  }

  return (
    <div id="proyectos" className="row proyectos">
      <h2 className="text-center">
        {props.idioma === "en" ? "Projects" : "Proyectos"}
      </h2>
      {proyects.map((proyecto) => (
        <div
          key={"proyecto-" + proyecto.id}
          id={"proyecto-" + proyecto.id}
          className="proyecto col-12 col-md-6 col-lg-4 d-flex justify-content-center"
        >
          <Link
            to={proyecto.page ? proyecto.page : "#"}
            state={{ proyecto: proyecto }}
          >
            <Card noPadding={true}>
              <img className="proyecto_img" src={proyecto.imgs[0]}></img>
              <div className="proyecto_info">
                <h3 className="proyecto_nombre">{proyecto.name}</h3>
                <p
                  className="proyecto_descripcionCorta"
                  dangerouslySetInnerHTML={{
                    __html: proyecto.short_description,
                  }}
                ></p>
                <div className="btn btn_primary">
                  {props.idioma === "en" ? "Read more" : "Ver más"}
                </div>
                <div className="proyecto_lenguages d-flex flex-wrap">
                  {proyecto.lenguages.map((lenguage) => (
                    <span
                      key={lenguage + proyecto.id}
                      className={"proyecto_lenguage " + lenguage}
                    >
                      {lenguage}
                    </span>
                  ))}
                </div>
              </div>
            </Card>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Proyectos;
