import Banner from './Banner';
import Container from "react-bootstrap/Container";
import Aboutme from './Aboutme';
import Tecnologias from './Tecnologias'
import Proyectos from './Proyectos';
import Contacto from './Contacto'


function Home(props) {
  return (
    <Container>
      <Banner idioma={props.idioma} />
      <Aboutme idioma={props.idioma} />
      <Tecnologias idioma={props.idioma} />
      <Proyectos idioma={props.idioma} />
      <Contacto idioma={props.idioma} />
    </Container>
  );
}

export default Home;
