import "./Proyecto.css";

import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "./Templates/Card";
import { useState, useEffect } from "react";

import { proyectsEN } from "../Data";
import { proyectsES } from "../Data";

import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import enlace from '../assets/enlace.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function Proyecto(props) {
  const location = useLocation();

  const [proyecto, setProyecto] = useState(null);
  const proyects = props.idioma === "es" ? proyectsES : proyectsEN;

  useEffect(() => {
    const foundProject = proyects.find((project) => project.page === location.pathname);
    setProyecto(foundProject);
  }, [location.pathname, proyects]);

  if (!proyecto) {
    return <Container>Cargando...</Container>;
  }

  const proyectoImgs = proyecto.imgs;

  return (
    <Container className="singleProyecto">
      <h1 className="text-center">{proyecto.name}</h1>

        <Swiper
          className="singleProyecto_slider"
          slidesPerView={1}
          autoHeight={true}
          zoom={{
            maxRatio: 5,
          }}
          speed="1000"
          modules={[Pagination, Autoplay]}
          navigation
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            stopOnLastSlide: true
          }}
          pagination={{
            clickable: true,
            el: ".swiper-custom-pagination",
          }}
        >
          {proyectoImgs.map((image, index) => (
            <SwiperSlide key={proyecto.id + index}>
              <Card>
                <img src={image}></img>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-custom-pagination" />
    
      <div className="row">
        <div className="col-12 col-md-8">
        <h2>{props.idioma === "en" ? "Description" : "Descripción"}</h2>
          <div
            className="singleProyecto_p"
            dangerouslySetInnerHTML={{
              __html: proyecto.description,
            }}
          ></div>
        </div>
        <div className="col-12 col-md-4">
          <Card>
            <div className="proyecto_lenguages d-flex flex-column">
              <h2>{props.idioma === "en" ? "Information" : "Información"}</h2>
              <h3>{props.idioma === "en" ? "Tools used" : "Herramientas utilizadas"}</h3>
              <div class="singleProyecto_herramientas">
                {proyecto.lenguages.map((lenguage) => (
                  <span
                    key={lenguage + proyecto.id}
                    className={"proyecto_lenguage " + lenguage}
                  >
                    {lenguage}
                  </span>
                ))}
              </div>
              {proyecto.url === "Privada" ? (
                <a className="singleProyecto_url-privado btn btn_primary">
                  {props.idioma === "en" ? "Private" : "Privada"}
                </a>
              ) : (
                <a className="singleProyecto_url btn btn_primary" href={proyecto.url} target="_black">
                  {props.idioma === "en" ? "Visit" : "Visitar"} <img src={enlace}></img>
                </a>
              )}
            </div>
          </Card>
        </div>
      </div>
    </Container>
  );
}

export default Proyecto;
