

import cp from "./assets/cp-mobile.jpg";
import cp1 from "./assets/cp1.png";
import cp2 from "./assets/cp2.png";

import arriendo1 from "./assets/arriendo1.png";
import arriendo2 from "./assets/arriendo2.png";
import arriendo3 from "./assets/arriendo3.png";

import bodega from "./assets/bodega.png";
import bodega2 from "./assets/bodega2.png";
import bodega3 from "./assets/bodega3.jpg";

import food from "./assets/food.png";

import huella from "./assets/huella.png";
import huella2 from "./assets/huella2.png";
import huella3 from "./assets/huella3.png";

import gastos from "./assets/gastos.png";

export const proyectsEN = [
  {
    id: 1,
    name: "Development of Cultural Heritage Center Website - for Católica University",
    short_description:
      "Development of a manageable website with WordPress CMS, creating a custom theme tailored to the university's needs",
    description:
      "<p>The challenge of this development was the level of customization and the diversity of manageable content types it includes. These are: clusters, trainings, services, and publications. In addition to complying with the graphic and development standards of the Catholic University. The theme was developed from scratch following the graphic line and components of the Catholic University.</p>",
    url: "https://centropatrimonio.uc.cl",
    imgs: [cp, cp1, cp2],
    page: "/proyecto/centropatrimonio",
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 2,
    name: "Apartment rental website for Arriendoactiva.cl",
    short_description: "Web to request visits to rent apartments created in Wordpress with a custom theme.",
    description: "<p>This website is developed using a custom-made theme for the client, it works as an apartment search system for users to send a visit request via email, it has a search engine, and custom pages for each building, the administrator can create new buildings and add to these the apartments you want, being a fully manageable and automated website.</p>",
    url: "https://www.arriendoactiva.cl",
    page: "/proyecto/arriendos_activa",
    imgs: [arriendo1, arriendo2, arriendo3],
    lenguages: ["PHP", "JavaScript", "HTML", "CSS"],
  },
  {
    id: 3,
    name: "Educational Water Footprint Calculator for Activa.cl",
    short_description:
      "Application that calculates your monthly water consumption based on simple questions and displays a detailed graph.",
    description:
      "<p>It's a form that calculates your monthly water consumption. The form is built with sliders, and each slider has a question. As the user answers, a side bar fills up with water, showing the \"liters/month\" you're using. After completing the form, the user receives the results by email and a link to a personal page that displays consumption details through graphs and previous calculations.</p>.",
    page: "/proyecto/huelladeagua",
    imgs: [huella, huella3, huella2],
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 4,
    name: "Warehouse Logistics Web App for iBikes.cl",
    short_description:
      "Application to help warehouse staff track orders before shipping.",
    description:
      "<p>This application was designed to assist warehouse staff in tracking products for an order before shipping, ensuring that they match the order. This is done by 'scanning' the products with a barcode scanner.</p> <p>It connects to the Woocommerce REST API, and warehouse staff enter the order code through an input field. The application then displays the products for that order, and the warehouse staff scans each product. The app checks for matches with the products from the API. After evaluating all the products, it updates the order status in Woocommerce and automatically print a PDF certificate capturing the verification time.</p><p>Definitely one of my favorite projects!</p>",
    url: "Privada",
    page: "/proyecto/bodega",
    imgs: [bodega, bodega2, bodega3],
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 6,
    name: "Shopping Cart Interface (Personal Project)",
    short_description:
      "Interface that simulates a shopping cart, adds and removes products.",
    description:
      "<p>Personal project to practice a wide range of React hooks. It's a comprehensive project that simulates a shopping cart where you can add or remove products.</p>",
    url: "https://github.com/ceberus1704/FoodShopingCart",
    page: "/proyecto/carrito",
    imgs: [food],
    lenguages: ["React", "HTML", "CSS"],
  },
  {
    id: 7,
    name: "Expense Control Interface (Personal Project)",
    short_description:
      "Interface that tracks all your expenses, adds your expenses, and displays a monthly graph.",
    description:
      "<p>React interface created as a personal project to help you track your expenses. All expenses are created through a form. Select a year, and it will display each month's expenses in the form of a graph.</p>",
    url: "https://github.com/ceberus1704/ControlDeGastos",
    page: "/proyecto/gastos",
    imgs: [gastos],
    lenguages: ["React", "HTML", "CSS"],
  },
];

export const proyectsES = [
  {
    id: 1,
    name: "Desarrollo de web Centro Patrimonio Cultural - Universidad Católica",
    short_description:
      "Desarrollo de web administrable con CMS de Wordpress, creando un tema a medida a las necesidades de la universidad",
    description:
      "<p>El reto de este desarrollo fue el nivel de personalización y la diversidad de tipos de contenidos administrables con los que cuenta. Estos son: clústeres, formaciones, servicios y publicaciones. Además de cumplir con los estándares gráficos y de desarrollo de la U.C. Se desarrolló el tema desde cero siguiendo la linea gráfica y los componentes de la U.C.</p>",
    url: "https://centropatrimonio.uc.cl",
    imgs: [cp, cp1, cp2],
    page: "/proyecto/centropatrimonio",
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 2,
    name: "Web de arriendo de departamentos para Arriendoactiva.cl",
    short_description:
      "Web para solicitar visitas para arrendar departamentos creada en Wordpress con un tema personalizado",
    description:
      "<p>Esta web está desarrollada usando un tema a hecho medida para el cliente, funciona como un sistema de búsqueda de departamentos para que los usuarios manden una solicitud de visita mediante correo electrónico, tiene un buscador, y páginas personalizadas para cada edificio, el administrador puede crear nuevos edificios y añadirle a estos los departamentos que desee, siendo una web totalmente administrable y automatizada.</p>",
    url: "www.arriendoactiva.cl",
    page: "/proyecto/arriendos_activa",
    imgs: [arriendo1, arriendo2, arriendo3],
    lenguages: ["PHP", "JavaScript", "HTML", "CSS"],
  },
  {
    id: 3,
    name: "Calculadora didáctica de huella de agua para Activa.cl",
    short_description:
      "Aplicación que por medio de preguntas sencillas calcula tu gasto de agua mensual y luego muestra una gráfica detallada.",
    description:
      '<p>Se trata de un formulario que calcula tu consumo de agua por mes. Es un formulario hecho con sliders, en cada slider hay una pregunta, el usuario a medida que contesta, una barra lateral se va llenando de agua, mostrando la cantidad de "litros/mes" que estás gastando, luego de completar el formulario se le envía a su correo los resultados y un enlace a una página personal en donde se muestran por medio de gráficas su consumo en detalle junto a resultados de cálculos anteriores.</p>.',
    url: "https://www.tuhuelladeagua.cl/tu-huella-de-agua/",
    page: "/proyecto/huelladeagua",
    imgs: [huella, huella3, huella2],
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 4,
    name: "Aplicación web de logística de bodega para iBikes.cl",
    short_description:
      "Aplicación para ayudar a los bodegueros a llevar control de las ordenes antes de despacharlas.",
    description:
      "<p>Esta aplicación consistía en ayudar a los bodegueros a llevar un control a la hora de contar los productos de una orden antes de ser despachada y evaluando que coincidieran con esta, por medio de <b>'pistolearlos'</b> con una pistola de código de barras.</p> <p>Su funcionamiento consiste en conectarse al API REST de Woocommerce, y por medio de un input el bodeguero introduce el codigo de una orden de compra, luego se le mostrará los productos de dicha orden, posteriormente el bodeguero pistolea cada producto en sus manos, la aplicación evalúa y busca una coincidencia con el producto del API, luego de evaluarlos todos, cambiará el estado de pedido de woocommerce y se imprimirá automáticamente un pdf que certifica todo el proceso y captura la hora de verificación.</p><p>Sin duda uno de mis proyectos favoritos!</p>",
    url: "Privada",
    page: "/proyecto/bodega",
    imgs: [bodega, bodega2, bodega3],
    lenguages: ["JavaScript", "PHP", "HTML", "CSS"],
  },
  {
    id: 6,
    name: "Interfaz de Carrito de Compras (Proyecto Personal)",
    short_description:
      "Interfaz que simula un carro de compras, agrega y elimina productos del carrito.",
    description:
      "<p>Proyecto personal para poner en práctica la grán mayoría de hooks de React, es bastante completa en este sentido, simula un carrito de compras en el que puedes añadir productos o eliminarlos.</p>",
    url: "https://github.com/ceberus1704/FoodShopingCart",
    page: "/proyecto/carrito",
    imgs: [food],
    lenguages: ["React", "HTML", "CSS"],
  },
  {
    id: 7,
    name: "Interfaz de Control de Gastos (Proyecto Personal)",
    short_description:
      "Interfaz que lleva el control de todos tus gastos, añade tus gastos y te muestra gráfica de cada mes.",
    description:
      "<p>Interfaz hecha en React como proyecto personal, sirve para llevar el control de tus gastos, todos los gastos son creados a partir de un formulario, selecciona un año y se mostrará cada gasto por més en forma de gráfica.</p>",
    url: "https://github.com/ceberus1704/ControlDeGastos",
    page: "/proyecto/gastos",
    imgs: [gastos],
    lenguages: ["React", "HTML", "CSS"],
  },
];
