import "./Banner.css";
import bannerImage from '../assets/omar.png'

const Banner = (props) => {
  return (
    <div className="row banner align-items-center justify-content-center">
      <div className="banner_content col-12 col-md-5 col-lg-5 d-flex flex-column justify-content-center">
        <h1 className="banner_title"><span className="high">{props.idioma === "en" ? "My name is" : "Mi nombe es"} <span className="resaltar">Omar</span>,</span><br></br> {props.idioma === "en" ? "i'm a" : "soy"} <span className="resaltar" >{props.idioma === "en" ? "Front-End Web Developer" : "Desarrollador Web Front-End"} </span> &#128075;</h1>
        <a className="btn btn_secondary" href="#proyectos">{props.idioma === "en" ? "My projects" : "Ver proyectos"} </a>
      </div>
      <div className="col-12 col-md-5 col-lg-4 d-flex justify-content-center">
        <div className="banner_img liquid">
            <img src={bannerImage}></img>
        </div>
      </div>
    </div>
  );
};

export default Banner;
