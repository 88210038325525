import React from "react";
import "./Card.css";

const Card = (props) => {

  const className = props.className ? " " + props.className : ""
  const noShadow = !props.noShadow ? " shadowOn" : "";
  const noPadding = !props.noPadding ? " p-2" : "";

  return (
    <React.Fragment>
      <div className={"card" + noShadow + noPadding + className}>{props.children}</div>
    </React.Fragment>
  );
};

export default Card;
